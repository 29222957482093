#background-video{
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%; 
    height: 100%; 
    z-index: -1;
}

#random-gif{
    top: 10px;
    z-index: 1;
}